import { BrowserRouter, Routes, Route } from "react-router-dom"
import { CattleProofTemplateFullPage } from './templates/cattleproof/full-page'
import { CalvingGroupListComponent } from "./components/user-tools/calving-group-list"
import { useGroupsHook } from "./hooks/groups.hook"
import { GroupUploadSupportDocsComponent } from "./components/user-tools/group-upload-support-docs"
import { useContext, useEffect, useState } from "react"
import { AccountContext } from "./providers/account.provider"
import { BootStrapSpinner } from "./components/ui/views/spinner"
import { AnimalEditorComponent } from "./components/user-tools/animal-editor"
import { GroupEditPage } from "./pages/group-edit"
import { DispositionPage } from "./pages/disposition"
import CertificatesPage from './pages/certificates'
import SSO from "./services/sso"
import Login from "./components/login"
import HerdGroups from './pages/herd-groups'
import Settings from './pages/settings'
import Explorer from './pages/explorer'
import Template from "./templates/cattleproof"
import TagManagerPage from "./pages/tag-manager"
import DispositionProvider from "./pages/disposition/provider"
import PermissionComponent from "./components/permissions"
import { ApplicationContext } from "./providers/application.provider"
import { Typography } from "@mui/material"
import { toDate } from "./helpers/util.helper"
import { CowHeadIcon } from "./components/ui/fontawesome"

export type IRouterModal =
{
    title?: string
    content?: string
    buttons?: any
    closeEvent?: any
    width?: string
    style?: any
    fullScreen?: boolean
}
export default function Router()
{
    return (
    <BrowserRouter>
        <Routes>
            <Route index element={ <HomePage /> } />
            <Route path='/' element={ <HomePage /> } />
            <Route path="/explorer" element={ <Explorer /> } />
            <Route path="/tag-manager/*" element={ <Login><PermissionComponent showAlert allowedAccount={['producer','admin']}><TagManagerPage /></PermissionComponent></Login> } />
            <Route path="/herd/group/" element={ <Login><HerdGroups /></Login> } />
            <Route path="/disposition/" element={ <Login><PermissionComponent showAlert allowedAccount={['producer','admin']}><DispositionProvider><DispositionPage /></DispositionProvider></PermissionComponent></Login> } />
            <Route path="/disposition/:gid" element={ <Login><PermissionComponent showAlert allowedAccount={['producer','admin']}><DispositionProvider><DispositionPage /></DispositionProvider></PermissionComponent></Login> } />
            <Route path="/settings/" element={ <Settings /> } />
            <Route path="/certified-herds/" element={ <Login><CertificatesPage /></Login> } />
            <Route path="/edit/group/:gid" element={ <GroupEditPage create /> } />
            <Route path="/create/group/" element={ <GroupEditPage create /> } />
            <Route path="/edit/animal-list/:groupid" element={ <AnimalEditorList /> } />
            <Route path="/explorer" element={ <CattleProofTemplateFullPage><Explorer /></CattleProofTemplateFullPage> } />
            <Route path="/sso" element={<CattleProofTemplateFullPage><SSO /></CattleProofTemplateFullPage>} />
            <Route path="*" element={ <HomePage /> } />
        </Routes>
    </BrowserRouter>
    )
}

export const AnimalEditorList = () => {
    const [ templateTitle, setTemplateTitle ] = useState('My Group Editor');
    const { currentApp } = useContext(ApplicationContext);

    useEffect(() => {
        if(currentApp?.answers?.group_name)
            setTemplateTitle(currentApp?.answers?.group_name);
    }, [ currentApp?.answers?.group_name ]);
    
    return (
        <Login>
            <Template
                title={ templateTitle }
                backButton="/"
                description={ <AnimalEditorDescriptionComponent /> }
                headerMargin={ false }
                border
            ><AnimalEditorComponent setTemplateTitle={setTemplateTitle} />
            </Template>
        </Login>
    )
}

export const AnimalEditorDescriptionComponent = () => {
    const { currentApp } = useContext(ApplicationContext);
    const data: any = currentApp?.answers;
    return (
        data?.group_name && <>
            <Typography variant="body2" className="mt-0">
                <>Animals In Group: <b>{data.herd_count}</b> <CowHeadIcon /></>
                <br />
                Certification Date: {toDate(data?.certified_at)}
            </Typography>
        </>)
}

export const HomePage = () => {
    const { loggedIn } = useContext(AccountContext)
    const { groups, groupState, setGroupState, error } = useGroupsHook()
    
    return (
        <Login>
            { loggedIn?
            <Template
                title={ <><i className="far fa-list-alt"></i>&nbsp;My Groups</> }
                description=''
            >
                { groups.count > 0 && groupState.ready? <GroupUploadSupportDocsComponent {...groups} /> : null }
                { groupState.ready? <>
                    <p>Below are all groups available for editing, pending review by CPV, or complete.</p>
                    <CalvingGroupListComponent {...{ groups, groupState, setGroupState, error }} />
                </> : null }
            </Template> : <BootStrapSpinner /> }
        </Login>
    )
}